<template>
  <div class="container-fluid footb">
    <div class="container foors">
      <div class="row">
        <div class="col-md-8 foot_l">
          <div class="foot_m1">
            <h3>{{$t('home.About')}}</h3>
            <ul>
              <li>
                <!-- <a href="#/about">{{$t('home.company')}}</a> -->
                <router-link to="/about">{{$t('home.company')}}</router-link>
              </li>
              <li>
                <!-- <a href="#/about">{{$t('home.history')}}</a> -->
                <router-link to="/about">{{$t('home.history')}}</router-link>

              </li>
              <li>
                <!-- <a href="#/about">{{$t('home.Qualification')}}</a> -->
                <router-link to="/about">{{$t('home.Qualification')}}</router-link>

              </li>
              <li>
                <!-- <a href="#/about">{{$t('home.Join')}}</a> -->
                <router-link to="/about">{{$t('home.Join')}}</router-link>

              </li>
            </ul>
          </div>
          <div class="foot_m1">
            <h3>{{$t('nav.software')}}</h3>
            <ul>
              <li>
                <!-- <a href="#/softp">{{$t('home.Electricity')}}</a> -->
                <!--<router-link to="/softp">{{$t('home.Electricity')}}</router-link>-->
                <a href="#/softp">海外仓综合管理系统</a>
              </li>
              <li>
                <!-- <a href="#/softp">{{$t('home.waybill')}}</a> -->
                <!--<router-link to="/softp">{{$t('home.waybill')}}</router-link>-->
                <a href="#/softp">仓库仓储物流管理系统</a>
              </li>
              <li>
                <!-- <a href="#/softp">{{$t('home.returns')}}</a> -->
                <!--<router-link to="/softp">{{$t('home.returns')}}</router-link>-->
                <a href="#/softp">代发货管理系统</a>
              </li>
              <li>
                <!-- <a href="#/softp">{{$t('home.Warehouse')}}</a> -->
                <!--<router-link to="/softp">{{$t('home.Warehouse')}}</router-link>-->
                <a href="#/softp">物流打单系统</a>
              </li>
              <li>
                <a href="#/softp">电商仓储物流管理系统</a>
              </li>
            </ul>
          </div>
          <div class="foot_m1">
            <h3>{{$t('nav.Contact')}}</h3>
            <ul>
              <li>
                <!-- <a href="#/contact">{{$t('home.Address')}}</a> -->
                <router-link to="/Contact">{{$t('home.Address')}}</router-link>

              </li>
              <li>
                <!-- <a href="#/contact">{{$t('home.Tel')}}</a> -->
                <router-link to="/Contact">{{$t('home.Tel')}}</router-link>
              </li>
              <!-- <li>
                <a href="#/contact">{{$t('home.email')}}</a>
              </li>
              <li>
                <a href="#/contact">{{$t('home.form')}}</a>
              </li> -->
            </ul>
          </div>
          <div class="foot_m1">
            <h3>{{$t('home.consulting')}}</h3>
            <ul>
              <li>
                <!-- <a href="#/infor">{{$t('home.CompanyNews')}}</a> -->
                <router-link to="/infor">{{$t('home.CompanyNews')}}</router-link>

              </li>
              <li>
                <!-- <a href="#/infor">{{$t('home.IndustryNews')}}</a> -->
                <router-link to="/infor">{{$t('home.IndustryNews')}}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 footl">
          <div class="er_img">
            <img :src="'http://admin.croxborder.com/' + wximg1" class="img-fluid" alt="Responsive image" >
<!--            <img src="../../assets/img/5.png" class="img-fluid" alt="Responsive image" />-->
          </div>
          <div class="er_img">
            <img :src="'http://admin.croxborder.com/' + wximg2" class="img-fluid" alt="Responsive image" >
<!--            <img src="../../assets/img/5.png" class="img-fluid" alt="Responsive image" />-->
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bgb">
      <div class="container">
        <div class="row">
          <div class="col-sm-3 col-6">
            <div class="bottom_img"></div>
            <div class="bottom_info">
              <img style="width: 36px;height: 36px;" src="../../assets/home/tel.png">
              <p>
                <span>{{$t('home.FixedTel')}}</span>
                <span>{{phoneNum}}</span>
              </p>

            </div>
          </div>
<!--          <P  class="xian"></P>-->
          <div class="col-sm-3 col-6">
            <div class="bottom_img"></div>
            <div class="bottom_info">
              <img style="width: 36px;height: 36px;" src="../../assets/home/email.png">
              <p>
                <span>{{$t('home.Email')}}</span>
                <span>{{email}}</span>
              </p>

            </div>
          </div>
<!--          <P class="xian"></P>-->
          <div class="col-sm-3 col-6">
            <div class="bottom_img"></div>
            <div class="bottom_info">
              <img style="width: 36px;height: 36px;" src="../../assets/home/qq.png">
              <p>
                <span>{{$t('home.QQ')}}</span>
                <span>{{qq}}</span>
              </p>
            </div>
          </div>
<!--          <P class="xian"></P>-->
          <div class="col-sm-3 col-6">
            <div class="bottom_img"></div>
            <div class="bottom_info">
              <img style="width: 36px;height: 36px;" src="../../assets/home/weixin.png">
              <p>
                <span>{{$t('home.WX')}}</span>
                <span>{{wx}}</span>
              </p>
            </div>
          </div>
        </div>
            <!-- ICP备案 -->
            <!--<div class="text">
                  {{title}} : {{ba}}
            </div>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {

  data() {
    return {
        inforList:'',
        phoneNum:'',
        qq:'',
        email:'',
        wx:'',
        ba:'',
        title:'',
        wximg1:'',
      wximg2:'',
      url:'http://admin.croxborder.com'
    }
  },
  mounted(){
this.getmapList()
  },
 
  methods:{
getmapList() {
      this.axios
        .post("/api/ad/setting")
        .then(res => {
          // console.log(res.data.data,"mammmmmmm33........m")
          this.inforList=res.data.data
          this.phoneNum=res.data.data[6].value;
          this.qq=res.data.data[53].value;
          this.wx=res.data.data[54].value;
          this.email=res.data.data[7].value;
          this.ba=res.data.data[9].value;
          this.title=res.data.data[9].title;
          this.wximg1 = res.data.data[10].value
          this.wximg2 = res.data.data[56].value
          // console.log(this.wx,"ooooooopoiiui");
          // console.log("qq", this.qq)
        });
    },
  }


};
</script>

<style  scoped>
.foors {
  height: 100%;
  margin-top: 30px;
  /* background-color: aqua; */
}
.footb {
  background-color: #4f4f4f;
  min-height: 300px;
  overflow: hidden;
  margin-top: 20px;
}
.foot_m1 h3 {
  color: #fdfdfd;
  font-size: 16px;
}

.foot_l {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.foot_m1 ul li {
  list-style: none;
  height: 30px;
  margin-top: 5px;
}
.foot_m1 ul li a {
  text-decoration: none;
  color: #bbbbbb;
}
.foot_m1 ul {
  padding: 0%;
  margin: 0;
}
.er_img {
  width: 132px;
  height: 132px;
  margin-left: 20px;
}
.footl {
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
}

.bottom_info {
  display: flex;
  margin-top:10px;
  justify-content: left;
  flex-wrap: nowrap;
}
.bottom_info img{
  margin:7px 14px;
}
.bottom_info p{
  display: flex;
  flex-direction: column;
}
.xian{
  width:1px;
  height:22px;
  margin-top: 25px;
  border:1px solid rgba(255,255,255,1);
  padding: 0;
}
.bgb {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
}
.text{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  align-items: center;
  
}
@media screen and (max-width:700px) {
  /* .row .col-sm-3 .bottom_info{
    font-size: 12px;
  } */
  .bgb {
    font-size: 12px;
  }
  .foot_m1 ul li a{
 font-size: 12px;
  }
  .foot_m1 h3{
    font-size: 14px;
  }
  .text{
     font-size: 12px;
  }
}
</style>