/* eslint-disable prettier/prettier */
module.exports = {
    nav: {
        home: '首页',
        software: '软件产品',
        resolveFan: '解决方案',
        customerCa: '客户案例',
        video: '视频教程',
        info: '资讯中心',
       
        Contact: '联系我们',
        About: '关于我们',
        All: '全部',
        warehouse: '海外仓解决方案',
        EN: "EN",
        softwareProduct:'软件产品'
    },
    home: {
        company: '公司新闻',
        product: '产品中心',
        Solution: '解决方案',
        openSolution: '开启解决方案',
        NewCenter: '新闻中心',
        Industry: '行业资讯',
        ServiceAdvantages: '服务优势',
        Elastic: '弹性收缩',
        ZeroList: '零漏单',
        Intelligent: '智能化预处理',
        tracking: '货物追踪',
        partner: '合作伙伴',
        Information: '留下联系方式',
        name: '请输入姓名',
        phone: '请输入电话',
        Submission: '提交信息',
        About: '关于我们',
        history: '发展历程',
        Qualification: '资质证书',
        Join: '诚聘英才',
        Electricity: '电商仓库',
        waybill: '电商运单',
        returns: '电商退货',
        Warehouse: '仓库工作流',
        Address: '公司地址',
        Tel: "联系电话",
        email: "邮箱",
        ALL:'全部',
        form: '表单',
        consulting: '资讯中心',
        FixedTel: '固定电话',
        Email: "邮箱",
        QQ: 'QQ',
        WX: '微信',
        CompanyNews:'公司新闻',
        IndustryNews:'行业新闻',
        LookDetails: "查看详情",
        time: '时间',
        source: '来源',
        aualification: '诚聘精英',
        certificate: '资质证书'
    },
    softp: {
        Overseas: '海外仓系统',
        CrossBorderTV: '跨境电视功能货代系统',
        Warehouse: '仓库退货系统',
        operation: '进口运转系统'
    },
    contact: {
        address: '经济开发区万达广场B5栋1520',
        email: '邮箱',
        phone: '电话'
    },
    about: {
        profile: '公司简介',
        detail: ' 深圳市科思宝信息技术有限公司一直致力于研发高效可控的跨境模式。精研跨境电商ERP、国际物流TMS、海外仓WMS和跨境分销M2B等管理系统。汇集目前国内强大的IT团队，创始人和开发巨匠均在跨境B2C、海外仓、国际物流等领域有5-12年研发经验，易仓目前已和国内外众多跨境卖家、海...',
        correction: '发展历程',
        job: '职位',
        experience: '经验',
        salary: '薪资',
        operation: '操作',
        Marketing: "市场专员",
        Chengdu: '成都',
        years: '两年',
        look: '查看',
    },
    resolve: {
        Outline: '方案概述',
        Scheme: "方案内容",
        characteristics: '方案特点',
        User: '用户特点',
            
        SoftwareRemark: '软件概述',
        SoftwareContent: "软件内容",
        SoftwareFeature: '软件特点',
        UserFeature: '用户特点',

    },
    soft:{
        SoftwareOverview:'软件概述',
        SoftwareContent:'软件内容',
        SoftwareCharacteristics:"软件特点",
        UserCharacteristics:"用户特点"

    }

}