/* eslint-disable prettier/prettier */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import $ from "jquery";
import axios from 'axios';
import qs from "qs";
import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video
import ElementUI from 'element-ui'
import VueI18n from 'vue-i18n'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)
axios.defaults.baseURL = 'http://admin.croxborder.com';
Vue.prototype.axios = axios;
Vue.prototype.qs = qs;
//引入bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import animate from "animate.css";
import VueAwesomeSwiper from 'vue-awesome-swiper'

// 引入放大镜插件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)


// require styles
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default global options } */)

//引入vant popup弹出层
import {
    Popup
} from "vant";

Vue.use(Popup);
Vue.use(animate);
Vue.use(VueI18n);

const messages = {
    'zh_CN': require('./i18n/zh'),
    'en_US': require('./i18n/en')
}

var localeLang = window.localStorage.getItem('locale_lang');
if (!localeLang) {
    localeLang = 'zh_CN';
}

const i18n = new VueI18n({
    locale: localeLang,
    messages: messages
});

Vue.config.productionTip = false;

Vue.prototype.$ct = function (obj, field) {
    if (localeLang == 'en_US') {
        let enfield = field + "_en";
        if (obj[enfield]) {
            return obj[enfield];
        }
    }
    if (obj[field]) {
        return obj[field];
    }
    return '';
}

//设置页面title
Vue.prototype.setTitle = function(title){
    var titleDom = document.getElementsByTagName('title');
    titleDom[0].innerHTML =title  + " " + webTitle;
}

new Vue({
    $,
    i18n,
    router,
    store,
    data() {
        let statusEn = 1;
        if (localeLang == 'en_US') {
            statusEn = 2;
        }
        return {
            statusEn: statusEn
        }
    },
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    },
    render: h => h(App)
}).$mount("#app");