<template>
  <!-- <div class="container-fluid d-sm-none"> -->
  <!-- d-none d-sm-none d-md-none d-lg-block -->
  <div class="container ki">
    <nav class="navbar navbar-expand-lg navbar-light">
      <img
        src="../assets/img/logo.png"
        alt
        srcset
        @click="jumpHome"
        class="img-fluid d-none d-sm-none d-md-none d-lg-block fr"

      />
      <img
        src="../assets/img/DD.png"
        alt
        srcset
        class="img-fluid d-block d-sm-block d-md-block d-lg-none"
      />
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon ju"></span>
      </button>
      <div class="collapse navbar-collapse hu" id="navbarNavDropdown">
        <ul class="navbar-nav dip">
          <li class="nav-item" v-for="(v, i) in navList" :key="i" @click="chang(i)">
            <template v-if="v.id == 10">
              <!--<a @click.stop.prevent="changeLang" style="color:white">{{$t('nav.EN')}}</a>-->
            </template>
            <router-link v-else :to="v.url">{{ v.title }}</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <!-- </div> -->
</template>
<script>
// import $ from "jquery"
export default {
  name: "HelloWorld",
  data() {
    return {
      navList: [],
      num: "",
      show: false,
      defaultIndex: 0
    };
  },
  mounted() {
    this.getMenus();
  },
  methods: {
    getMenus(){
      this.navList  = [
        { title: this.$i18n.t('nav.home'), url: "/", id: 11 },
        { title: this.$i18n.t('nav.software'), url: "/softp", id: 3 },
        { title: this.$i18n.t('nav.resolveFan'), url: "/resolveFan", id: 4 },
        { title: this.$i18n.t('nav.customerCa'), url: "/customerCa", id: 5 },
        // { title: this.$i18n.t('nav.video'), url: "/video", id: 6 },
        { title: this.$i18n.t('nav.info'), url: "/infor", id: 7 },
        { title: this.$i18n.t('nav.Contact'), url: "/Contact", id: 8 },
        { title: this.$i18n.t('nav.About'), url: "/about", id: 9 },
        { title: "中/EN", url: "/", id: 10 }
      ];
    },
    chang(i) {
      this.num = i;
      this.defaultIndex = i;
    },
    showPopup() {
      this.show = true;
    },
    jumpHome() {
      this.$router.push({ path: "/" });
    },
    changeLang(){
      let localeLang = window.localStorage.getItem('locale_lang');
      if (localeLang) {
        if(localeLang == 'en_US'){
          localeLang = 'zh_CN';
        }else{
          localeLang = 'en_US';
        }
      } else {
        localeLang = 'en_US';
      }
      window.localStorage.setItem('locale_lang', localeLang);
      window.location.reload();
    }
  },
  created: function() {
    // console.log($(".container"));
  },
    watch: {
      '$i18n.locale'(){
        this.getMenus();
      }
    }
};
</script>
<style scoped lang="scss">
// .active {
//   border-bottom: 3px solid #ee3323;
//   color: #ee3323;
// }
.container {
  min-height: 60px;
  // display: flex;
  // align-items: center;
  // background-color: hotpink;
}
.container > .row {
  min-height: 60px;
}
.col-lg-1 a {
  text-decoration: none;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  min-height: 80px;
}
.navbar-toggler {
  padding: 0;
  // font-size: 0;
}
.navbar-nav li .aa {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  text-align: center;
  // padding-top: 10px;
  display: block;
  // margin-bottom: 10px;
  height: 30px;
}
// .navbar-nav li .dd{
//     border-bottom: 3px solid #EE3323;
//     color: #EE3323;
// }
.lop{
  cursor: pointer;
}
.dip {
  display: flex;
  justify-content: space-around;
}
.dip li {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.dip li a{
  text-decoration: none;
}
.dip li a:hover {
  // border-bottom: 3px solid #ee3323;
  // color: #ee3323;
  color:#fff;
  text-decoration: underline;
  // border-bottom: 1px solid #fff;
}
.hu {
  width: 100%;
  // display: flex;
  margin-left: 20px;
}
// .tan {
//   // width: 100%;
//   width: 50%;
//   height: 100%;
//   background-color: red;
//   position: absolute;
// }
.fr{
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  .dip .nav-item a {
    font-size: 15px;
    min-height: 10px;
  }
  .dip li {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-nav li .aa {
    height: 15px;
  }
  .navbar-nav li .dd {
    border: none;
  }
  .dip {
    background-color: black;
    width: 50%;
    height: 100%;
    justify-content: flex-start;
    display: flex;
    flex-direction: column !important;
    text-align: center;
    margin-left: auto;
  }
  .dip li {
    // width: 50%;
    margin-top: 30px;
    width: 100%;
    // background-color: red;
  }
  .hu {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 36px;
    left: -20px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .ju {
    // color: white;
    background-color: gray;
  }
  .dip li a:hover {
    color: #ee3323;
  }
}
</style>
