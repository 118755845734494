<template>
  <div class="cebian" @mouseenter="funCmou" ref="rightDom" @mouseleave="funLeave">
    <div class="cebian_top">
      <img src="../../assets/img/leftTop.png" alt />
      <a href="#">返回顶部</a>
    </div>

    <div class="cebian_m">
      <img src="../../assets/img/leftPhone.png" alt class="img-fluid" />
      {{telephone}}
    </div>

    <div class="cebian_m">
      <img src="../../assets/img/leftqq.png" alt />
      <a :href="'tencent://Message/?uin=' + qq + '&Menu=yes'">在线咨询</a>
    </div>

    <div class="cebian_m">
      <img src="../../assets/img/leftwx.png" alt />
      微信
    </div>

    <div class="cebian_er">
      <img :src="'http://admin.croxborder.com/' + wxqrcode" alt srcset class="img-fluid" />
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      telephone: '',
      qq: '',
      wxqrcode: ''
    }
  },
  mounted(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.axios.post(
              "api/ad/setting"
      ).then(res => {
        let data = res.data.data;
        for(let key in data){
          let item = data[key];
          if(item.vari == 'tel'){
            this.telephone = item.value
          }
          if(item.vari == 'jio'){
            this.qq = item.value
          }
          if(item.vari == 'public_qrcode'){
            this.wxqrcode = item.value
          }
        }
      });
    },
    funCmou() {
      let box = this.$refs.rightDom;
      // box.style.backgroundColor="red"
      $(".cebian").stop();
      $(".cebian_er").stop();
      $(".cebian").animate({ right: "150px", height: "430px" });
      $(".cebian_er").show(500, function() {});
    },
    funLeave() {
      let box = this.$refs.rightDom;
      // box.style.backgroundColor="green"
      $(".cebian").stop();
      $(".cebian_er").stop();
      $(".cebian").animate({ right: "0px", height: "300px" });
      $(".cebian_er").hide(50, function() {});
    }
  }
};
</script>


<style  scoped>
.cebian_top img {
  margin-right: 5px;
  width: 50px;
  height: 50px;
}
.cebian_m img {
  margin-right: 5px;
  /* width: 50px;
  height: 50px; */
}
.cebian_m {
  margin-top: 20px;
}
.cebian .cebian_m :hover {
  color: #65a8ff;
}
.home {
  position: relative;
}
.cebian {
  width: 200px;
  /* height: 365px; */
  height: 300px;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  right: 0;
  top: 30%;
  box-sizing: border-box;
  padding: 15px;
  z-index: 999;
  margin-right: -135px;
  /* border-start-end-radius: 30px; */
  border-radius: 10px;
}
.cebian_top {
  width: 100%;
  display: flex;
  height: 50px;
  /* background-color: green; */
  align-items: center;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border-bottom: 1px solid rgba(244, 244, 244, 0.6);
}
.cebian_top a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}
.cebian_m {
  width: 100%;
  display: flex;
  height: 50px;
  /* background-color: green; */
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border-bottom: 1px solid rgba(26, 24, 24, 0.5);
}
.cebian_m a {
  color: white;
  text-decoration: none;
}
.cebian_er {
  width: 100%;
  height: 132rpx;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: none;
}
.cebian_er img {
  width: 132px;
  height: 132px;
}
.cebian .cebian_top :hover {
  color: #65a8ff;
}
@media screen and (max-width: 1200px) {
  .cebian {
    width: 130px;
    height: 300px;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    right: 0;
    top: 40%;
    box-sizing: border-box;
    padding: 15px;
    z-index: 999;
  }
  .cebian_top {
    width: 100%;
    display: flex;
    height: 30px;
    /* background-color: green; */
    align-items: center;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    border-bottom: 1px solid rgba(244, 244, 244, 0.6);
  }
  .cebian_top a {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  .cebian_m {
    width: 100%;
    display: flex;
    height: 30px;
    /* background-color: green; */
    align-items: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    border-bottom: 1px solid rgba(244, 244, 244, 0.6);
  }
  .cebian_er {
    width: 100%;
    height: 102rpx;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .cebian_er img {
    width: 102px;
    height: 102px;
  }
  .cebian .cebian_top :hover {
    color: #65a8ff;
  }
}

@media screen and (max-width: 900px) {
  .cebian {
    display: none;
  }
}
</style>
