<template>
  <div class="container boli">
    <div class="row">
      <div class="col-md-5">
        <div class="linee d-none d-sm-none d-md-none d-lg-block"></div>
      </div>
      <div class="col-md-2">{{$t('home.Information')}}</div>
      <div class="col-md-5">
        <div class="linee d-none d-sm-none d-md-none d-lg-block"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="inpu">
          <input type="text" :placeholder="$t('home.name')" v-model="filter.truename" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="inpu">
          <input type="number" :placeholder="$t('home.phone')" v-model="filter.mobile" />
        </div>
      </div>
      <div class="col-md-4">
        <button
          type="button"
          class="btn btn-warning btn-lg hj h3font"
          @click="tijiao"
        >{{$t('home.Submission')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        truename: "",
        mobile: "",
        email: "",
        qq: "",
        address: "",
        content: "",
        status_en: this.$root.statusEn
      }
    };
  },

  mounted() {},
  methods: {
    tijiao() {
      var TEL_REGEXP = /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/;
      if (!TEL_REGEXP.test(this.filter.mobile)) {
        alert("请输入正确的手机号");
      } else {
        var params = {
          truename: this.filter.truename,
          mobile: this.filter.mobile,
          status_en: this.$root.statusEn
        };
        this.axios.post("api/feedback/create", { params }).then(res => {
          console.log(res, "sdas");
          (this.filter.truename = ""),
            (this.filter.mobile = ""),
            alert("提交成功");
        });
      }

      // console.log(this.filter.truename, "sad");
    }
  }
};
</script>


<style  scoped>
.boli {
  margin-top: 80px;
}
.linee {
  height: 1px;
  width: 100%;
  background-color: gray;
}
.col-md-5 {
  justify-content: center;
  align-items: center;
  display: flex;
}
.col-md-2 {
  justify-content: center;
  align-items: center;
  display: flex;
}
.col-md-4 {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 110px;
  /* background-color: green; */
}
.col-md-4 .inpu {
  width: 316px;
  height: 60px;
  border: 1px solid rgba(153, 153, 153, 1);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-md-4 .inpu input {
  width: 250px;
  background: none;
  outline: none;
  border: none;
}
.hj {
  width: 300px;
  height: 55px;
  background: rgba(246, 146, 30, 1);
}
@media screen and (max-width: 600px) {
  .h3font {
    font-size: 12px;
  }
  .boli{
    margin-top: 10px;
  }
  .col-md-4{
    height: 70px;
  }
  .col-md-4 .inpu{
    height: 48px;
  }
  .hj{
    height: 45px;
  }
  .inpu input{
    font-size: 12px;
  }
}
</style>