<template>
  <div>
    <div>
      <div class="container kiu" v-for="(item,index) in list" :key="index">
        <div class="row" :class="{'row1':index %2 !=0}" @click="jumpDetail(item.id)">
          <div class="col-lg-7">
            <div class="card" style="width: 100%;">
              <div class="card-body">
                <h3 class="card-title h3f/ont h4font">{{item.title}}</h3>
                <h5 class="card-title h5font h3font">{{item.ex_title}}</h5>
                <div class="line"></div>
                <p class="card-text pfont h3font" v-html="item.content"></p>
                <!-- <a href="#/resolve" class="btn btn-warning glad h3font">{{$t('home.LookDetails')}}</a> -->
              </div>
            </div>
          </div>

          <div class="col-lg-5 de">
            <img
              class="car img-fluid img-fluid1"
              :src="'http://admin.croxborder.com/'+item.image"
              alt="Card image cap"
            />
          </div>
        </div>
      </div>
      <!-- 间距太小,用空盒子代替 -->
      <div class="boxx" v-if="this.$route.name == 'softp'" style="height:100px;"></div>
    </div>
  </div>
</template>

<script>
// d-none d-sm-none d-md-none d-lg-block
export default {
  data() {
    return {
      search: {
        meun: 4,
        status: 1,
        status_en: this.$root.statusEn
      },
      list: []
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {

    jumpDetail(e) {
      var id = e;
      // 原本是跳转到软件产品页面在跳转到产品详情，现在直接跳详情页
      //   this.$router.push({ path: "/softp",query:{id:id} });

      this.$router.push({ path: "/softInfor", query: { id: id } });
    },

    getlist() {
      
      if (this.$route.name == "home") {
        this.search.status = 1;
      }else{
        delete this.search.status
      }
      //  首页
      this.axios
        .post("api/ad/menu", this.qs.stringify(this.search))
        .then(res => {
          this.list = res.data.data.data;
          // console.log("产品页产品中心1111111",res);
        });
    }
  }
};
</script>

<style scoped>
.box {
  float: left;
  background: orange;
}

.col-lg-5 {
  display: flex !important;
  align-items: center;
}
.row1 {
  width: 104%;
  display: flex;
  /* background: red; */
  /* justify-content: flex-start; */
  flex-direction: row-reverse;
}
.kiu {
  cursor: pointer;
}
.kiu :hover {
  color: #65a8ff;
}
.container {
  display: flex;
  justify-content: space-between;
}
.h5font {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.card {
  border: none;
}
.line {
  width: 110px;
  height: 5px;
  background-color: #d4d4d4;
  margin-bottom: 10px;
}
.pfont {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.glad {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: white;
  background-color: #f6921e;
}
.de {
  width: 100%;
  overflow: hidden;
  float: left;
}
.img-fluid {
  max-width: 104% !important;
}
.de img {
  object-fit: cover;
  transition: all 0.4s;
}
.de img:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 600px) {
  .de {
    width: 100%;
  }
  .jio {
    display: none;
  }
  .h3font {
    font-size: 12px;
    /* color: red; */
  }
  .h4font {
    font-size: 18px;
    font-weight: 900;
    color: black;
  }

  .kiu {
    justify-content: center;
  }
}
/* @media screen and (max-width: 600px){
         .cebian{
           display: none;
         }
         .btn{
           font-size: 12px;
         }
         
     } */
</style>
