<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    watch: {},
    computed: {},
    methods: {
      geturl() {
        let url=window.location.hostname;
        this.axios.post('api/ad/setting').then(res=>{
          var title=document.createElement('title');
          title.innerHTML =res.data.data[0].value;
          var head=document.getElementsByTagName('head')[0];
          var meta1=document.createElement('meta');
          meta1.name='keywords';
          meta1.content=res.data.data[1].value;
          var meta2=document.createElement('meta');
          meta2.name='description';
          meta2.content=res.data.data[2].value;
          head.append(title,meta1,meta2);
        })
      }
    },
    created() {},
    mounted() {
    }
  };
</script>

<style lang="scss">
// *{
//   padding: 0;
//   margin: 0;
// }
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #FFF;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;

    &.router-link-exact-active {
      // color: #EE3223;
      color: #fff;
      display: inline-block;
      background: #EE3223;
      width: 80px;
      height: 30px;
      line-height: 30px;
      border-radius: 10px;
    }
  }
}
</style>
