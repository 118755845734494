<template>
    <div class="container-fluid boo">
        <div class="container bo">
            <div class="top">
                <p class="text-center h3font pingjia">客户评价</p>
                <div class="line_mm"></div>
                <p class="text-center h3font pingjiaE">EVALUATION</p>
            </div>
    
            <div class="bd-example">
                <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators HU">
                        <li data-target="#carouselExampleCaptions" v-for="(item,index) of lbList" :key="index" :class="{'active':index==1}" data-slide-to="index"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item" v-for="(item,index) of lbList" :key="index" :class="{'active':index==1}">
                            <div class="huj">
                                <div class="top">
                                    <p class="text-center h3font">{{item.title}}</p>
                                    <div class="line_m"></div>
                                    <p class="text-center h3font">{{item.ex_title}}</p>
                                </div>
                                <p class="card-text pfont h3font ly" v-html="item.content"></p>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
                    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: "active",
            lbList: []
        };
    },
    mounted() {
        this.getlbList();
    },
    methods: {
        getlbList() {
            this.axios
                .post("api/ad/menu", this.qs.stringify({ meun: 19, status_en: this.$root.statusEn }))
                .then(res => {
                    console.log(res, 21113);
                    this.lbList = res.data.data.data;
                    console.log("ly", this.lbList)
                });
        }
    }
};
</script>

<style scoped>
.HU {
    top: 166px;
}

.HU>li {
    background-color: chocolate;
}

.huj {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.boo {
    height: 329px;
    background-color: #f7f8fa;
}

.bo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pingjia {
    font-size: 1.4rem;
    margin-bottom: 0.6rem;
}

.line_mm {
    width: 40%;
    height: 1px;
    margin: 0 auto;
    border-bottom: 1px solid black;
}

.line_m {
    background-color: rgba(51, 51, 51, 1);
    /* margin: 13px 0 10px 0; */
    /* width: 42px;
  height: 1px; */
    border: 1px solid rgba(51, 51, 51, 1);
}

.pingjiaE {
    font-size: 24px;
    color: #cdcdcd;
    margin-top: 0.6rem;
}

.cen {
    /* margin: 10px 0 10px 0; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}

.ly {
    width: 1000px;
    height: 7.5rem;
    /* background: red; */
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

@media screen and (max-width: 500px) {
    .HU {
        top: 155px;
    }
    .h3font {
        font-size: 12px;
    }
}

/* .carousel-control-prev{
  top:91px;
background-color: red;
}
.carousel-control-next{
  top:91px;
  background-color: red;
} */
</style>