<template>
  <div class="container gay kiu">
    <div class="row" @click="jumpDetail">
      <div class="col-lg-5 de d-none d-sm-none d-md-none d-lg-block">
        <img
          class="car img-fluid  d-none d-sm-none d-md-none d-lg-block"
          :src="'http://admin.croxborder.com/'+list.image"
          alt="Card image cap"
        />
      </div>
      <div class="col-lg-7">
        <div class="card" style="width: 100%;">
          <div class="card-body flex-right">
            <h3 class="card-title tex fonth  h4font">{{list.title}}</h3>
            <h5 class="card-title h5font tex fonth">{{list.ex_title}}</h5>
            <div class="line left"></div>
            <p class="card-text pfont fonth" v-html="list.content"></p>
            <a href="#/resolve" class="btn btn-warning glad right fonth">{{$t('home.LookDetails')}}</a>
          </div>
        </div>
      </div>
        <div class="col-lg-5 de d-block d-sm-block d-md-block d-lg-none">
        <img
          class="car img-fluid  d-block  d-sm-block d-md-block d-lg-none"
          :src="'http://admin.croxborder.com/'+list.image"
          alt="Card image cap"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: "",
      list: []
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    jumpDetail() {
      this.$router.push({ path: "/softp" });
    },
    getlist() {
      this.axios
        .post(
          "api/ad/menu",
          this.qs.stringify({ meun: 11, status_en: this.$root.statusEn })
        )
        .then(res => {
          this.list = res.data.data.data[0];
        });
    }
  }
};
</script>

<style scoped>
.col-lg-5{
  display: flex !important;
  align-items: center;
}
.kiu{
  cursor: pointer;
}
.kiu :hover{
  color: #65A8FF
}
.container {
  display: flex;
  justify-content: space-between;
}
.gay {
  /* color: blue; */
}
.gay {
  background-color: #f4f4f4;
}
.h5font {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.card {
  border: none;
  background-color: #f4f4f4;
}
.line {
  width: 110px;
  height: 5px;
  background-color: #d4d4d4;
  margin-bottom: 10px;
  /* float: right; */
}
.right {
  float: right;
}
.pfont p{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  float: right;
}
.pfont {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  /* float: right; */
}
.glad {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: white;
  background-color: #f6921e;
}
.tex {
  text-align: end;
}

.de {
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
}
.de img {
  object-fit: cover;
  transition: all 0.4s;
}
.de img:hover {
  transform: scale(1.1);
}
.flex-right {
  display: flex;
  flex-direction: column;
 align-items: flex-end;
}
.row {
  width: 100%;
}
@media screen and (max-width:700px) {
  .de{
   width: 100%;
   height: auto;
  }
  .jio{
    display: none;
  }
  .fonth{
    font-size: 12px;
  }
  .kiu{
    justify-content: center;
  }
  .h4font{
    font-size: 18px;
    color: black;
    font-weight: 900;
  }
 
}
</style>
