<template>
  <div class="container kiu">
    <div class="row" @click="jumpDetail">
      <div class="col-lg-7">
        <div class="card" style="width: 100%;">
          <div class="card-body">
            <h3 class="card-title h3font h4font">{{list.title}}</h3>
            <h5 class="card-title h5font h3font">{{list.ex_title}}</h5>
            <div class="line"></div>
            <p class="card-text pfont h3font" v-html="list.content"></p>
            <a href="#/resolve" class="btn btn-warning glad">{{$t('home.LookDetails')}}</a>
          </div>
        </div>
      </div>
      <div class="col-lg-5  de">
        <img
          class="car img-fluid "
          :src="'http://admin.croxborder.com/'+list.image"
          alt="Card image cap"
        />
      </div>
    </div>
  </div>
</template>

<script>
// d-none d-sm-none d-md-none d-lg-block
export default {
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    jumpDetail() {
      this.$router.push({ path: "/softp" });
    },
    getlist() {
      this.axios
        .post("api/ad/menu", this.qs.stringify({ meun: 15 , status_en: this.$root.statusEn}))
        .then(res => {
          this.list = res.data.data.data[0];
        });
    }
  }
};
</script>

<style scoped>
.col-lg-5{
    display: flex !important;
  align-items: center;
}
.row{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.kiu{
  cursor: pointer;
}
.kiu :hover{
  color: #65A8FF
}
.container {
  display: flex;
  justify-content: space-between;
}
.h5font {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.card {
  border: none;
}
.line {
  width: 110px;
  height: 5px;
  background-color: #d4d4d4;
  margin-bottom: 10px;
}
.pfont {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.glad {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: white;
  background-color: #f6921e;
}
.de {
  width: 100%;
  overflow: hidden;
}
.de img {
  object-fit: cover;
  transition: all 0.4s;
}
.de img:hover {
  transform: scale(1.1);
}
@media screen and (max-width:600px) {
   .de{
   width: 100%;
  }
  .jio{
    display: none;
  }
  .h3font{
    font-size: 12px;
  }
  .h4font{
    font-size: 18px;
    font-weight: 900;
    color: black;
  }
  .kiu{
    justify-content: center;
  }
  /* .container{
    padding-right: 0;
    
  } */
 
}
</style>
