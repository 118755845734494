<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="new_top">
          <div class="newTop_left"></div>
          <p class="h3font">{{$t('home.company')}}</p>
        </div>
        <div class="card" style="width: 100%;">
          <div class="card-body cont" @click="newDetail1(fms1.id)">
            <div class="imgb d-none d-sm-none d-md-none d-lg-block">

              <img class="img-fluid"  style="height:145px;width:100%" :src="'http://admin.croxborder.com/'+this.list[0].thumb" alt="Card image cap" />

            </div>
            <div class="text kuo">
              <h5 class="card-title fontH5 h4font">{{fms1.title}}</h5>
              <p class="card-text h3font d-none d-sm-none d-md-none d-lg-block">{{fms1.ex_title}}</p>
              <div class="time d-none d-sm-none d-md-none d-lg-block">{{fms1.date}}</div>
            </div>
          </div>

          <!-- .slice(1,3) -->
          <!-- 公司新闻 -->
          <ul class="list-group list-group-flush" v-if="showw">
            <li
              class="list-group-item"
              @click="newDetail2(item.id)"
              v-for="(item,index) of firstLimit"
              :key="index"
            >
              <span class="tetx h3font">{{item.title}}</span>
              <span class="timee h3font">{{item.date}}</span>
            </li>
          </ul>
          <!-- 公司新闻下方新闻列表 -->
          <ul class="list-group list-group-flush" v-else>
            <li
              class="list-group-item"
              @click="newDetail2(item.id)"
              v-for="(item,index) of moreList"
              :key="index"
            >
              <span class="tetx h3font">{{item.title}}</span>
              <span class="timee h3font">{{item.date}}</span>
            </li>
          </ul>

          <div class="more" @click="funMore" v-show="showFun">
            <span v-if="showw">显示更多</span>
            <span v-else>收起更多</span>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="new_top">
          <div class="newTop_left"></div>
          <p class="h3font">{{$t('home.Industry')}}</p>
        </div>
        <div class="card ko" style="width: 100%;">
          <!-- 行业资讯 -->
          <div class="card-body cont" @click="newDetail3(fms2.id)">
            <div class="imgb d-none d-sm-none d-md-none d-lg-block">
              <!-- <img
                class="card-img-top img-fluid d-none d-sm-none d-md-none d-lg-block"
                :src="'http://admin.croxborder.com/'+images"
                alt="Card image cap"
              /> -->

 <img  style="height:145px;width:100%"
                class="card-img-top img-fluid d-none d-sm-none d-md-none d-lg-block"
                :src="'http://admin.croxborder.com/'+this.list2[0].thumb"
                alt="Card image cap"
              />

 

            </div>

            <div class="text kuo">
              <h5 class="card-title fontH5 h4font">{{fms2.title}}</h5>

              <p class="card-text h3font d-none d-sm-none d-md-none d-lg-block">{{fms2.ex_title}}</p>
              <div class="time d-none d-sm-none d-md-none d-lg-block">{{fms2.date}}</div>
            </div>
          </div>
          <!-- 行业资讯下方列表 -->
          <ul class="list-group list-group-flush" v-if="show">
            <li
              class="list-group-item"
              @click="newDetail4(item.id)"
              v-for="(item,index) of secondLimit"
              :key="index"
            >
              <span class="tetx h3font">{{item.title}}</span>
              <span class="timee h3font">{{item.date}}</span>
            </li>
          </ul>
          <ul class="list-group list-group-flush" v-else>
            <li
              class="list-group-item"
              @click="newDetail4(item.id)"
              v-for="(item,index) of moreL"
              :key="index"
            >
              <span class="tetx h3font">{{item.title}}</span>
              <span class="timee h3font">{{item.date}}</span>
            </li>
          </ul>

          <div class="more" @click="funM" v-show="showFu">
            <span v-if="show">显示更多</span>
            <span v-else>收起更多</span>
          </div>
          <!-- <div class="more" @click="funM" v-else></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newcenter",
  // d-none d-sm-none d-md-none d-lg-block
  data() {
    return {
      fms1: [],
      fms2: [],
      list: [],
      list2: [],
      limit: 4,
      meun: "5",
      image: "",
      images: "",
      showw: true,
      show: true,
      moreList: [],
      moreL: [],
      test:[
        {
          text:"一"
        },
         {
          text:"二"
        },
         {
          text:"三"
        },
         {
          text:"四"
        },
         {
          text:"五"
        },

      ]
    };
  },
  computed: {
    firstLimit: function() {
      if (this.list.length > 3) {
        return this.list.slice(1, 3);
        // return this.list.slice(0, 2);
// 
      } else {
        return this.list;
      }
    },
    secondLimit: function() {
      if (this.list2.length > 3) {
        return this.list2.slice(1, 3);
        // return this.list2.slice(0, 2);

      } else {
        return this.list2;
      }
    },

    showFun: function() {
      if (this.list.length > 3) {
        return true;
      } else {
        return false;
      }
    },
    showFu: function() {
      if (this.list2.length > 3) {
        return true;
      } else {
        return false;
      }
    }
  },

  mounted() {
    // console.log(this.test.splice(1,3))
    this.getlist();
    this.getlist2();
    // console.log(this.moreList,'......')
  },
  methods: {
    // 显示更多:
    funMore() {
      this.showw = !this.showw;
    },
    // 显示更多:
    funM() {
      this.show = !this.show;
    },

    newDetail1(id) {
      this.$router.push({
        name: "inforer",
        query: { id }
      });
    },
    newDetail3(id) {
      this.$router.push({
        name: "inforer",
        query: { id }
      });
    },
    newDetail4(id) {
      this.$router.push({
        name: "inforer",
        query: { id }
      });
    },
    newDetail2(id) {
      this.$router.push({
        name: "inforer",
        query: { id }
      });
    },
    getlist() {
      this.axios
        .post(
          "api/ad/at",
          this.qs.stringify({ meun: 27, status_en: this.$root.statusEn })
        )
        .then(res => {
          this.moreList = res.data.data;
          this.list = res.data.data;
          this.fms1 = res.data.data.shift();
          this.images = this.fms1.image;
          console.log('公司新闻',this.list);
        });
    },
    getlist2() {
      this.axios
        .post(
          "api/ad/at?",
          this.qs.stringify({ meun: 28, status_en: this.$root.statusEn })
        )
        .then(res => {
          this.moreL = res.data.data;
          this.list2 = res.data.data;
          this.fms2 = res.data.data.shift();
          this.image = this.fms2.image;
          // console.log(this.image, "ooooooooooooo787878787787ooo");
          console.log('行业新闻',this.list2);

        });
    }
  }
};
</script>

<style scoped>
.more {
  width: 100px;
  height: 30px;
  background-color: orange;
  border-radius: 10%;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
  cursor: pointer;
  color: white;
}
.container {
  display: flex;
  justify-content: space-between;
}
/* .col-lg-6 {
    padding: 0;
} */
.cont {
  display: flex;
  width: 100%;
  padding: 0;
  cursor: pointer;
}
.card {
  border: none;
}
.imgb {
  min-width: 246px;
  min-height: 157px;
}
.imgb img {
  /* width: 100%; */
  height: 260px;
  cursor: pointer;
  transition: all 0.4s;
}
.imgb img:hover {
  transform: scale(1.1);
}
.new_top {
  width: 100%;
  min-height: 58px;
  display: flex;
  border-bottom: 1px solid rgba(245, 245, 245, 1);
  margin-bottom: 10px;
}
.newTop_left {
  width: 6px;
  height: 28px;
  background: rgba(255, 153, 34, 1);
}
.new_top p {
  margin-left: 10px;
  min-width: 80px;
  min-height: 19px;
  font-size: 1.25em;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.text {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  position: relative;
  padding-left: 23px;
}
.kuo :hover {
  color: #65a8ff;
}
.text p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-indent: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.fontH5 {
  /* text-align: end; */
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
}
.time {
  width: 100px;
  height: 15px;
  position: absolute;
  right: 5px;
  bottom: 10px;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}
.list-group-item {
  display: flex;
  justify-content: space-between;
}

.imgb {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.imgb > img {
  object-fit: cover;
}
li {
  transition: all 0.4s;
  cursor: pointer;
}

li .tetx {
  display: block;
  /* width: 424px; */
  /* height: 50px; */

  white-space: nowrap; /*规定段落中的文本不进行换行*/
  overflow: hidden; /*内容会被修剪，并且其余内容是不可见的。*/
  text-overflow: ellipsis; /*显示省略号来代表被修剪的文本*/
}
/* li span :nth-child(1){
  display: block;
  width: 150px;
  height: 50px;
  background-color: gold;
} */
/* li .koo{
   display: block;
  width: 150px;
  height: 50px;
  background-color: gold;
} */
li span {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
li .timee {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  min-width: 70px;
}

li :hover {
  color: #65a8ff;
}
@media screen and (max-width: 700px) {
  li .tetx {
    white-space: nowrap; /*规定段落中的文本不进行换行*/
    overflow: hidden; /*内容会被修剪，并且其余内容是不可见的。*/
    text-overflow: ellipsis; /*显示省略号来代表被修剪的文本*/
  }
  .imgb img {
    height: auto;
  }
  .new_top {
    margin-top: 8px;
  }
  .list-group-item {
    padding: 0;
  }
  /* .col-lg-6{
  padding: 0;
} */
  .time {
    width: 90px;
    height: 15px;
    position: absolute;
    right: -17px;
    bottom: 0px;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
  }
  .text {
    min-width: 90px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    position: relative;
    /* padding-left: 23px; */
    padding: 0;
  }
  .h3font {
    font-size: 12px;
  }
  .h4font {
    font-size: 14px;
  }
  .li .timee {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }
}
</style>
