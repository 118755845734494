<template>
  <div class="container-fluid" :class="num == '1' ? 'gg' : ''" >
    <div class="container cen">
      <p class="text-center title tt">{{fms}}</p>
      <p class="text-center intro tt1">{{fms2}}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["num","fms","fms2"],
  mounted() {
    console.log(this.num);
  }
};
</script>

<style scoped>
.title {
  font-size: 24px;
  position: relative;
}
.intro {
  font-size: 24px;
  color: #cdcdcd;
}
.title::before {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 50%;
  background-color: #333;
  left: 50%;
  height: 1px;
  transform: translateX(-50%);
}
.line_m {
  background-color: rgba(51, 51, 51, 1);
  margin: 13px 0 10px 0;
  /* width:42px;
    height:1px; */
  border: 1px solid rgba(51, 51, 51, 1);
}
.cen {
  /* margin: 10px 0 10px 0; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  /* margin-bottom:20px; */
}
.gg {
  background-color: #f5f5f5;
}
@media screen and (max-width:600px) {
.tt{
  font-size: 16px;
  font-weight: 900;
}
.tt1{
  font-size: 16px;
}
.cen{
  margin: 0;
}




}
</style>
