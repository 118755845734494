<template>
    <div class="container-fluid img_box">
        <img src="../assets/img/bb.png" class="img-fluid" alt=""/>
    </div>
</template>

<script>
export default {
    
}
</script>

<style  scoped>
.img_box{
    width: 100%;
    min-height: 100px;
    /* background-color: green; */
    /* background: url(../assets/img/bb.png) no-repeat; */
    /* background-size: 100% 100%; */ 
}
.img_box img{
    width: 100%;
}
</style>