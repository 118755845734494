<template>
  <div class="container se ">
    <div class="row">
      <div class="col-lg-3 col-sm-4">
        <div class="img_bb">
          <img src="../assets/img/20.png" class="img-p img-fluid" alt />
        </div>
        <div class="tetx">{{$t('home.Elastic')}}</div>
      </div>
      <div class="col-lg-3 col-sm-4">
        <div class="img_bb item2">
          <img src="../assets/img/20.png" class="img-p img-fluid" alt />
        </div>
        <div class="tetx">{{$t('home.ZeroList')}}</div>
      </div>
      <div class="col-lg-3 col-sm-4">
        <div class="img_bb item3">
          <img src="../assets/img/20.png" class="img-p img-fluid" alt />
        </div>
        <div class="tetx">{{$t('home.Intelligent')}}</div>
      </div>
      <div class="col-lg-3 col-sm-4">
        <div class="img_bb item4">
          <img src="../assets/img/20.png" class="img-p img-fluid" alt />
        </div>
        <div class="tetx">{{$t('home.tracking')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// d-none d-sm-none d-md-none d-lg-block
export default {};
</script>

<style scoped>
.se {
  margin-bottom: 40px;
  margin-top: 40px;
}
.col-lg-3 > .img_bb {
  width: 138px;
  height: 138px;
  background: rgba(246, 146, 30, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.item2 {
  background-color: #ff7366 !important;
}
.item3 {
  background-color: #65a8ff !important;
}

.item4 {
  background-color: #ff9666 !important;
}
.col-lg-3 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.img_bb {
  transition: all 0.4s;
}
.img_bb:hover {
  transform: scale(1.1);
  transition: all 0.4s;
  box-shadow: 1px 1px 0px 4px #ccc;
}
@media screen and (max-width: 600px){
  .row{
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
      }
  .col-lg-3 > .img_bb{
    width: 50px;
    height: 50px;
  }
  .tetx{
    font-size: 12px;
  }
  .col-sm-4{
    padding: 0;
  }
  .se{
    margin-bottom: 10px;
    margin-top: 0px;
  }
}
</style>
