/* eslint-disable prettier/prettier */
module.exports = {
    nav: {
        home: 'Home',
        software: 'Software',
        resolveFan: 'resolveFan',
        customerCa: 'customerCa',
        video: 'video',
        info: 'info',
        Contact: 'Contact',
        About: 'About',
        All: 'All',
        warehouse:'warehouse',
        EN:'中文',
        softwareProduct:'softwareProduct'
    },
    home: {
        company: 'company',
        product: 'product',
        Solution: 'Solution',
        openSolution: 'openSolution',
        NewCenter: 'NewCenter',
        Industry: 'Industry',
        ServiceAdvantages: 'ServiceAdvantages',
        Elastic: 'Elastic',
        ZeroList: 'ZeroList',
        Intelligent: "Intelligent",
        tracking: 'tracking',
        partner: 'partner',
        Information: 'Information',
        name: 'name',
        phone: 'phone',
        Submission: 'Submission',
        About: 'About',
        history: "history",
        Qualification: 'Qualification',
        Join: 'Join',
        Electricity: 'Electricity',
        waybill: 'waybill',
        returns: 'returns',
        Warehouse: 'Warehouse',
        Address: 'Address',
        Tel: "Tel",
        Email: 'Email',
        ALL:'ALL',
        form: 'form',
        consulting: "consulting",
        FixedTel: 'FixedTel',
        QQ:'QQ',
        WX:'WX',
        LookDetails: 'LookDetails',
        time: "time",
        source: "source",
        aualification:'aualification',
        certificate:'certificate',
        Wechat:'Wechat',
        CompanyNews:'CompanyNews',
        IndustryNews:'IndustryNews'
    },
    softp: {
        Overseas: 'Overseas',
        CrossBorderTV: "CrossBorderTV",
        Warehouse: 'Warehouse',
        operation: 'operation'
    },
    contact: {
        address: '1520, building B5, Wanda Plaza, Economic Development Zone',
        email: 'email',
        phone: 'phone'
    },
    about: {
        profile: 'profile',
        detail: 'Shenzhen kesibao Information Technology Co., Ltd. has been committed to the research and development of efficient and controllable cross-border mode. Research on cross-border e-commerce ERP, international logistics TMS, overseas warehouse WMS and cross-border distribution M2b management systems. With a strong it team in China, the founders and development giants have 5-12 years of R &amp; D experience in cross-border B2C, overseas warehouse, international logistics and other fields. At present, e-warehouse has worked with many cross-border sellers at home and abroad',
        correction: 'correction',
        job: 'job',
        experience: 'experience',
        salary: 'salary',
        operation: 'operation',
        Marketing: 'Marketing',
        Chengdu: 'Chengdu',
        years: 'years',
        look:'look'
    },
    resolve: {
        Outline: 'Outline',
        Scheme: 'Scheme',
        characteristics: 'characteristics',
        User:'User',

        SoftwareRemark: 'SoftwareRemark:',
        SoftwareContent: 'SoftwareContent',
        SoftwareFeature: 'SoftwareFeature',
        UserFeature:'User',

    },
    soft:{
        SoftwareOverview:'SoftwareOverview',
        SoftwareContent:'SoftwareContent',
        SoftwareCharacteristics:"Characteristics",
        UserCharacteristics:"UserCharacteristics"

    }

}