<template>
    <div class="container fms">
        <div class="swiper-pc">
            <swiper :options="swiperOptionfms" class="swi" v-if="list.length > 0">
                <swiper-slide v-for="(item,index) of list" :key="index">
                    <div class="card gg" style="width: 18rem;">
                        <div class="top" :class="index%3==0? 'bhu':index%3==1? 'phu':index%3==2? 'koui':'koui'">
                            <div class="imgBox">
                                <img
                                        v-if="item.image"
                                        class="card-img-top tupian"
                                        :src="'http://admin.croxborder.com/'+item.image"
                                        alt="Card image cap"
                                />
                            </div>
                            <h5 class="text-center tex h3font">{{item.title}}</h5>
                            <p class="text-center tep h3font">{{item.ex_title}}</p>
                        </div>
                        <div class="card-body bod">
                            <p class="card-text tt h3font" v-html="item.content"></p>
                            <div
                                    class="botto h3font"
                                    :class="index%3==0? 'ppphu':index%3==1? 'pphu':index%3==2? 'kpoui':'kpoui'"
                            >
                                <router-link :to="{name:'resolve',query:{id:item.id}}">{{$t('home.openSolution')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev gu" slot="button-prev"></div>
            <div class="swiper-button-next gu" slot="button-next"></div>
        </div>
        <div class="swiper-mobile">
            <swiper :options="mobileSwiper" class="swi" v-if="list.length > 0">
                <swiper-slide v-for="(item,index) of list" :key="index">
                    <div class="card gg">
                        <div class="top" :class="index%3==0? 'bhu':index%3==1? 'phu':index%3==2? 'koui':'koui'">
                            <div class="imgBox">
                                <img
                                        v-if="item.image"
                                        class="card-img-top tupian"
                                        :src="'http://admin.croxborder.com/'+item.image"
                                        alt="Card image cap"
                                />
                            </div>
                            <h5 class="text-center tex h3font">{{item.title}}</h5>
                            <p class="text-center tep h3font">{{item.ex_title}}</p>
                        </div>
                        <div class="card-body bod">
                            <p class="card-text tt h3font" v-html="item.content"></p>
                            <div
                                    class="botto h3font"
                                    :class="index%3==0? 'ppphu':index%3==1? 'pphu':index%3==2? 'kpoui':'kpoui'"
                            >
                                <router-link :to="{name:'resolve',query:{id:item.id}}">{{$t('home.openSolution')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                list: [],
                limit: 3,
                meun: 7,
                swiperOptionfms: {
                    loop: true,
                    autoplay: false,
                    slidesPerView: 3,
                    spaceBetween: 30,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                mobileSwiper: {
                    loop: true,
                    autoplay: {
                        delay: 3000
                    },
                    slidesPerView: 1
                }
            };
        },
        mounted() {
            this.getlist();
        },
        methods: {
            getlist() {
                var params = {
                    limit: 100,
                    meun: 7,
                    status_en: this.$root.statusEn
                };
                this.axios.post("api/ad/menu", params).then(res => {
                    this.list = res.data.data.data;
                    // console.log(this.list, "55555555555555555");
                });
            }
        }
    };
</script>

<style scoped>
    .tt {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        height: 100px;
        /* background: red */
        color: rgba(102, 102, 102, 1);
    }

    .swiper-container {
        position: static !important;
    }

    .swiper-wrapper {
        position: static !important;
    }

    .fms {
        position: relative;
    }

    .swiper-button-next {
        box-shadow: 0px 3px 6px rgba(219, 228, 242, 0.93) !important;
        /* background-color: red; */
        background-image: url("../assets/img/2556.png") !important;
        border-radius: 50% !important;
        /* z-index: 999 !important; */
        background-size: cover;
        right: -34px !important;
        left: auto !important;
        border: none !important;
        z-index: 999 !important;
        width: 60px !important;
        height: 60px !important;
        background-size: 80%;
        background-position: 60%;
    }

    .swiper-button-prev {
        box-shadow: 0px 3px 6px rgba(219, 228, 242, 0.93) !important;
        border-radius: 50% !important;
        border: none !important;
        left: -100px !important;
        right: auto !important;
        z-index: 999 !important;
        width: 60px !important;
        height: 60px !important;
        background-image: url("../assets/img/3356.png") !important;
        background-size: 80%;
    }

    .swi {
        height: 550px;
        /* width: 600px; */
        /* background-color: forestgreen; */
    }

    .card {
        margin-top: 40px;
        margin-bottom: 20px;
        box-shadow: 0px 0px 30px 5px #efefef;
    }

    .imgBox {
        /* width: 67px;
        height: 59px; */
        margin-bottom: 10px;
        margin-top: 50px;
    }

    .imgBox .tupian {
        width: 60px;
        height: 60px;
    }

    .phu {
        background: #f6921e;
    }

    .ppphu {
        border: 1px solid #65a8ff;
    }

    .pphu {
        border: 1px solid #f6921e;
    }

    .kpoui {
        border: 1px solid #ff7366;
    }

    .bhu {
        background: #65a8ff;
    }

    .koui {
        background: #ff7366;
        height: 192px;
    }

    .top {
        width: 100%;
        min-height: 50px;
        /* background-color: #65a8ff; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .tex {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }

    .tep {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }

    .tt /deep/ p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin: 0;
    }

    .bod :hover {
        color: #65a8ff;
    }

    .botto {
        width: 174px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        /* margin-top: 55px; */
    }

    .botto a {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        text-decoration: none;
    }

    .bod {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;
    }

    .gg {
        transition: all 0.6s;
        cursor: pointer;
    }

    .gg:hover {
        transform: scale(0.9);
        color: #65a8ff;
    }
    .swiper-mobile {
        margin-bottom: 1rem;
    }
    .swiper-mobile .card {
        box-shadow: none;
    }
    @media screen and (max-width: 800px) {
        .gu {
            display: none;
        }

        .swiper-slide {
            /*width: 323px !important;*/
            height: 200px;
        }

        .swiper-pc {
            display: none;
        }
    }

    @media screen and (min-width: 801px) {
        .swiper-mobile {
            display: none;
        }
    }

    @media screen and (max-width: 600px) {
        .h3font {
            font-size: 12px;
        }

        .h3font p {
            font-size: 12px;
        }

        .gg {
            height: 100%;
        }

        .swi {
            height: 400px;
        }

        .swiper-slide {
            /*width: 323px !important;*/
            height: 400px;
        }

        .top {
            height: 200px;
            width: 100% ；;
        }

        .imgBox img {
            width: 50px;
            height: 50px;
        }

        .card {
            margin-top: 0;
        }

        .tt /deep/ p {
            font-size: 12px !important;
        }

        .botto {
            width: 150px;
            height: 40px;
            line-height: 40px;
            font-size: 12px;
            margin-top: 44px;
        }
    }
</style>
