<template>
    <div id="hezuo">
        <div class="partner-pc">
            <div class="container" :class="num == '1' ? 'gay' : ''">
                <viewer :images="list" class="imgBigBox">
                    <div>
                        <img class="imgBox"
                             v-for="item in list"
                             :src="'http://admin.croxborder.com/'+item.image"
                             :key="item.index"
                        />
                    </div>
                </viewer>
            </div>
        </div>
        <div class="partenr-mobile">
            <swiper :options="swiperOption" class="partenr-swiper" v-if="list.length > 0">
                <swiper-slide v-for="(item, index) in list" :key="index">
                    <div class="img-box-m">
                        <img :src="'http://admin.croxborder.com/' + item.image" />
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
    //  d-sm-none d-none d-lg-block

    export default {
        data() {
            return {
                swiperOption: {
                    loop: true,
                    slidesPerView: 3,
                    slidesPerColumn: 1,
                    autoplay: {
                        delay: 3000
                    },
                    spaceBetween: 20,
                    slidesPerGroup: 2
                }
            };
        },
        mounted() {
        },
        methods: {},
        props: ["list", "num"]
    };
</script>

<style scoped>
    .partenr-swiper {
        width: calc(100% - 30px);
        maring-left: auto;
        margin-right: auto;
        padding: 1rem;
    }
    .partenr-swiper .swiper-slide .img-box-m{
        border: 1px solid black;
        overflow: hidden;
    }
    .partenr-swiper .swiper-slide .img-box-m img {
        width: 100%;
    }
    .imgBigBox {
        width: 1096px;
        /* background: red; */
        display: flex;
        justify-content: space-between;
    }

    .imgBox {
        width: 254px;
        height: 125px;
        padding: 10px 10px;
        margin-right: 20px;
        margin-bottom: 10px;
        border: 1px solid black;
    }

    #hezuo .kk {
        width: 200px;
        height: 106px;
    }

    .container {
        min-height: 100px;
    }

    .imgg {
        width: 100%;
        height: 100%;
        overflow: hidden;
        /* margin-bottom: 20px;/ */
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000;
    }

    .imgg > img {
        max-width: 80%;
        max-height: 80%;
        transition: all 0.4s;
        object-fit: cover;
    }

    .imgg > img:hover {
        transition: all 0.4s;
        transform: scale(1.1);
    }

    .col-md-3 {
        overflow: hidden;
    }

    .gay {
        background-color: #f5f5f5;
    }

    >>> /deep/ .swiper-wrapper {
        text-align: center !important;
    }
    @media screen and (max-width: 800px){
        .partner-pc {
            display: none;
        }
    }
    @media screen and (min-width: 801px) {
        .partenr-mobile {
            display: none;
        }
    }
    @media screen and (max-width: 600px) {

    }
</style>
