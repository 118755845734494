
<template>
  <div class="home">
    <blaners :imgList="imgList"></blaners>
    <Nav class="navv" :fms="this.$i18n.t('home.product')" :fms2="title3"></Nav>
<product ></product>


    <Nav :fms="this.$i18n.t('home.Solution')" :fms2="title2"></Nav>
    <solution-Fu></solution-Fu>
    <Nav :fms="this.$i18n.t('home.NewCenter')" :fms2="title5"></Nav>
    <newcenter></newcenter>
    <Nav :fms="this.$i18n.t('home.ServiceAdvantages')" :fms2="title7"></Nav>
    <server></server>
    <imgr></imgr>
    <Nav :fms="this.$i18n.t('home.partner')" :fms2="title9"></Nav>
    <hezuo :list='list'></hezuo>
    <evaluate></evaluate>
    <lianxi></lianxi>
    <foot></foot>
    <left/>


  </div>
</template>

<script>
// @ is an alias to /src
// import Headr from "@/components/Head.vue";
import blaners from "@/components/publick/blanerS.vue";
import Nav from "@/components/nav.vue";
import Procenter from "@/components/proCenter.vue";
import Procentery from "@/components/proCenterY.vue";
import solutionFu from "@/components/solutionFu.vue";
import newcenter from "@/components/newcenter.vue";
import server from "@/components/server.vue";
import imgr from "@/components/img.vue";
import foot from "@/components/publick/foot.vue";
import hezuo from "@/components/hezuo.vue";
import lianxi from "@/components/lianxi.vue";
import evaluate from "@/components/evaluate";
import proCenterC from "@/components/proCenterC.vue";
import left from "@/components/publick/leftT.vue";
import product from "@/views/product.vue";



export default {
  name: "home",
  components: {
    // Headr,
    blaners,
    Nav,
    Procenter,
    Procentery,
    proCenterC,
    solutionFu,
    newcenter,
    server,
    imgr,
    foot,
    hezuo,
    lianxi,
    evaluate,
    left,
    product
  },
  data() {
    return {
      imgList: [],
      list:[],
      title1: "",
      title3: "SERVICE INTRODCTION",
      title: "解决方案",
      title2: "THE SOLUTIONS",
      title4: "新闻中心",
      title5: "THE NEWS CENTER",
      title6: "服务优势",
      title7: "SERVICE INTRODCTION",
      title8: "合作伙伴",
      title9: "DOCKING CHANNELS",
      bool:false,
    };
  },
  mounted() {
    this.getimgList();
    this. getlist()
    //监听窗口滚动距离
    window.addEventListener('scroll', this.handleScroll);
    this.setTitle('');
  },
  methods: {
 handleScroll () {
    let scrollY = window.scrollY;//获取窗口滚动距离
    if (scrollY > 1000) {
      this.bool=true;
    } else {
      this.bool=false;
    }
   },
    getimgList() {
      this.axios
        .post(
          "api/ad/index",
          this.qs.stringify({ meun: 11, status_en: this.$root.statusEn })
        )
        .then(res => {
          this.imgList = res.data.data;
        });
    },
//合作伙伴
      getlist() {
      this.axios
        .post("api/ad/img", this.qs.stringify({ id: 48 }))
        .then(res => {
          console.log(res.data.data,'0000022585858')
          this.list=res.data.data;
        });
    }
  }
};
</script>

<style  scoped>
.navv{
  margin-top: 50px;
}
.cebian_top img{
    margin-right: 5px;
  width: 50px;
  height: 50px;
}
.cebian_m img{
  margin-right: 5px;
  /* width: 50px;
  height: 50px; */
}
.cebian_m{
  margin-top: 20px;
}
.cebian  .cebian_m :hover{
  color: #65a8ff;
}
.home {
  position: relative;
}

.cebian_top {
  width: 100%;
  display: flex;
  height: 50px;
  /* background-color: green; */
  align-items: center;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border-bottom: 1px solid rgba(244,244,244,0.6);
}
.cebian_top a{
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}
.cebian_m {
  width: 100%;
  display: flex;
  height: 50px;
  /* background-color: green; */
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border-bottom: 1px solid rgba(26, 24, 24, 0.5);
}
.cebian_m a{
  color: white;
  text-decoration: none;
}
.cebian_er{
  width: 100%;
  height: 132rpx;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: none;
}
.cebian_er img{
  width: 132px;
  height: 132px;
}
.cebian .cebian_top :hover {
  color: #65a8ff;
}
    @media screen and (max-width: 1200px){
      /* .cebian {
  width: 130px;
  height: 300px;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  right: 0;
  top: 30%;
  box-sizing: border-box;
  padding: 15px;
  z-index: 999;
} */
.cebian_top {
  width: 100%;
  display: flex;
  height: 30px;
  /* background-color: green; */
  align-items: center;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border-bottom: 1px solid rgba(244,244,244,0.6);
}
.cebian_top a{
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}
.cebian_m {
  width: 100%;
  display: flex;
  height: 30px;
  /* background-color: green; */
  align-items: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
    border-bottom: 1px solid rgba(244,244,244,0.6);
}
.cebian_er{
  width: 100%;
  height: 102rpx;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.cebian_er img{
  width: 102px;
  height: 102px;
}
.cebian .cebian_top :hover {
  color: #65a8ff;
}
    }


     @media screen and (max-width: 900px){
         .cebian{
           display: none;
         }
     }
</style>
