<template>
  <div class="container con">
    <solution></solution>
  </div>
</template>


<script>
import solution from "./solution";
export default {
  components: {
    solution
  }
};
</script>

<style scoped>
.con {
  text-align: center;
}
</style>