<template>
  <div class="container-fluid">
    <div class="container-fluid big">
      <Headr></Headr>
    </div>
    <div id="carouselExampleIndicators" class="carousel slide small" data-ride="carousel" data-interval="1500">
      <ol class="carousel-indicators" v-if="imgList.length>1">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner"  >
        <div v-for="(v,i) in imgList" :key="i" class="carousel-item" :class="i==0? 'active':''">
          <img :src= "'http://admin.croxborder.com/'+v.image" class="d-block w-100" alt="..." />
        </div>
       
      </div>
    <div v-if="imgList.length>1">
      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    </div>
 
  </div>
</template>

<script>
import Headr from "@/components/Head.vue";
import { watch } from 'fs';
export default {
  data() {
    return {
        show: false,
        
    };
  },
  components: {
    Headr
  },
  props: ['imgList'],
  methods: {
     showPopup() {
      this.show = true;
    }
  },
  mounted(){
  },
  watch:{
    imgList(){
         console.log(this.imgList.length,"opopopopop")

    }
  }
};
</script>

<style scoped>
.container-fluid{
  padding: 0;
}
.small {
  position: relative;
}
.big {
  position: absolute;
  top: 0;
  /* left: 0; */
  z-index: 999;
  box-shadow: 0px 20px 40px 0px rgba(228, 228, 228, 0.075);
}
</style>